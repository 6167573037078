<template>
	<div>
		<div
			class="flex items-center justify-center min-h-screen px-4 py-12 sm:px-6 lg:px-8 auth"
		>
			<div class="w-full max-w-md p-8 bg-white rounded-lg shadow-xl space-y-8">
				<div>
					<svg
						style="enable-background:new 0 0 64 64;"
						version="1.1"
						viewBox="0 0 64 64"
						xml:space="preserve"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						class="w-auto h-12 mx-auto"
					>
						<g>
							<path
								fill="#2563eb"
								d="M19.9,50.4c-0.6-3.1-3.1-5.7-6.3-6.3c-5.6-1.1-10.5,3.8-9.4,9.4c0.6,3.1,3.1,5.7,6.3,6.3   C16.1,60.9,20.9,56.1,19.9,50.4z"
							/>
							<path
								fill="#2563eb"
								d="M4,4v10.7C29,14.7,49.3,35,49.3,60H60C60,29.1,34.9,4,4,4z"
							/>
							<path
								fill="#2563eb"
								d="M30.7,60h10.7c0-20.6-16.7-37.3-37.3-37.3v10.7C18.7,33.3,30.7,45.3,30.7,60z"
							/>
						</g>
					</svg>
					<h2 class="mt-6 text-3xl font-bold text-center text-gray-900">
						{{ $i18n("Sign in to your account") }}
					</h2>
				</div>
				<form @submit.prevent="signInMethod" class="mt-8 space-y-6">
					<input type="hidden" name="remember" value="true" />
					<div class="rounded-md shadow-sm -space-y-px">
						<div>
							<label for="email-address" class="sr-only">{{ $i18n("Email") }}</label>
							<input
								v-model="email"
								id="email-address"
								name="email"
								type="email"
								autocomplete="email"
								required
								class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
								:placeholder="$i18n('Email')"
							/>
						</div>
						<div>
							<label for="password" class="sr-only">{{ $i18n("Password") }}</label>
							<input
								v-model="password"
								id="password"
								name="password"
								type="password"
								autocomplete="current-password"
								required
								class="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
								:placeholder="$i18n('Password')"
							/>
						</div>
					</div>

					<div>
						<button
							@click="signInMethod"
							type="submit"
							class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent group rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						>
							<span class="absolute inset-y-0 left-0 flex items-center pl-3">
								<!-- Heroicon name: solid/lock-closed -->
								<svg
									class="w-5 h-5 text-blue-500 group-hover:text-blue-400"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fill-rule="evenodd"
										d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
										clip-rule="evenodd"
									/>
								</svg>
							</span>
							{{ $i18n("Sign in") }}
						</button>
						<div class="mt-5 md:flex space-l-10">
							<LangSelector />
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import LangSelector from "@/components/LangSelector";

export default {
	name: "Auth",
	data() {
		return {
			email: "",
			password: "",
		};
	},
	components: {
		LangSelector,
	},
	computed: {
		hasLastPage() {
			const {
				$router: {
					history: { index },
				},
			} = this;

			return index > 0;
		},
	},
	methods: {
		...mapActions("auth", ["signIn"]),
		async signInMethod() {
			try {
				const { signIn, email, password } = this;
				await signIn({ email, password });
			} catch (error) {
				console.debug(error);
			}
		},
	},
};
</script>
